@import '~bootstrap/scss/_forms.scss';

.form-check-input:focus {
  border-color: $gray-600;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 10%) !important;
}

.form-select,
.form-control {
  border-radius: 0;
  background-color: $white;
  &:focus {
    border-color: $primary;
    background-color: $white;
    box-shadow: none;
  }
}
/*input.form-control {
  border-color: $input-bg;
  padding-right: 2.5rem;

  &:focus {
    border-color: $white;
    background-color: $white;
    box-shadow: $box-shadow;
  }
}

select.form-select {
  height: $input-height;
  background-color: $input-bg;
  border: none;
}*/

.form-label.required:after {
  content: '*';
  margin-left: 0.125rem;
  color: $primary;
}
