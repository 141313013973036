@import 'src/assets/scss/import';

.sticky-aside {
  position: sticky;
  top: 1rem;
  padding: 1rem;
  background-color: $light;

  .selected-accessories {
    &-toggle {
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      svg {
        color: $primary;
        margin-right: 0.5rem;
        transition: $transition-base;
      }

      p {
        margin: 0;
      }

      &-open {
        svg {
          transform: rotate(-180deg);
        }
      }
    }

    &-count {
      display: grid;
      place-items: center;
      background-color: $black;
      border-radius: 50%;
      color: $white;
      width: 1.25rem;
      height: 1.25rem;
      margin-left: 0.5rem;

      span {
        line-height: 1.25rem;
      }
    }

    &-list {
      margin: 0.5rem 0;
      padding: 0 1.25rem;
      background-color: $white;
      border-radius: 4px;

      .list-group-item {
        padding: 1.25rem 0;
        border: none;

        &:not(:last-of-type) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        }

        .btn {
          display: flex;
          margin-left: 0.5rem;

          svg {
            margin: 0;
          }
        }
      }
    }
  }
}
