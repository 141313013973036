@import 'colors';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

$accordion-icon-color: $primary;
$accordion-icon-active-color: $primary;
$accordion-icon-width: 1rem;
$accordion-button-active-bg: inherit;
$accordion-button-active-color: inherit;
$accordion-button-focus-border-color: inherit;
$accordion-button-focus-box-shadow: none;
$accordion-border-radius: 0;
$accordion-button-padding-y: 0.5rem;
$accordion-button-padding-x: 0;
$accordion-body-padding-x: 0;

$input-btn-padding-y: 0.625rem;
$input-btn-padding-x: 1.875rem;
$input-btn-line-height: 2.4rem;

$btn-border-radius: $border-radius-pill;
$btn-box-shadow: none;
$btn-transition: background-color 0.3s ease 0s;

$card-border-color: $gray-200;
$card-border-radius: 0;

$form-check-input-border-radius: 0.15em;

$input-bg: rgba(0, 0, 0, 0.05);
$input-hover-bg: $gray-300;
$input-focus-bg: $gray-400;
$input-focus-box-shadow: none;
$input-height: 3.25rem;

$list-group-item-padding-x: 0;

$navbar-brand-font-size: $font-size-base;
$nav-pills-link-active-color: $secondary;

$popover-box-shadow: $box-shadow-lg;

$enable-negative-margins: true;

$box-shadow-up: rgb(0 0 0 / 10%) 0px -5px 20px;
