$primary: #efdf00;
$primary-light: #f8eb4c;
$primary-dark: $primary;
$secondary: #000;

$light-hover: #f2f2f2;

$gray-100: #f2f2f2;
$gray-200: $gray-100;
$gray-300: $gray-100;
$gray-400: #d9d9d6;
$gray-500: #8c8c8b;
$gray-600: $gray-500;
$gray-700: #656666;
$gray-800: $gray-700;
$gray-900: #3e3f40;