@import 'src/assets/scss/import';
@import '~bootstrap/scss/list-group';

.list-group-flush > .list-group-item:last-child {
  border-width: 0 0 1px;
}

.accordion-toggle-custom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  padding: 0.5rem 0;
  font-size: $font-size-sm;

  span.active svg {
    transform: rotate(180deg);
  }

  svg {
    color: $primary;
    transition: 0.2s all;
  }
}
