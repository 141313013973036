@import 'src/assets/scss/import';

.hero-text {
  position: relative;
  @include media-breakpoint-up(md) {
    position: absolute;
  }
}

.fullscreen-wrapper {
  position: relative;

  &-active {
    .hero-text {
      top: 10rem !important;
      left: 10rem !important;
    }

    .btn-fullscreen-exit {
      position: absolute !important;
      display: inline-block !important;
      height: 1.5rem;
      right: 10rem;
      top: 10.25rem;
    }
  }

  &:not(.fullscreen-wrapper-active) .btn-fullscreen-exit {
    display: none;
  }
}

.fullscreen.fullscreen-enabled {
  background-color: $gray-200;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-fullscreen {
  position: absolute;
  right: 0;
  bottom: -2.2rem;
  width: auto;
  svg {
    color: inherit;
  }

  &.fullscreen-enabled {
    display: block !important;
  }
}

.v360-image-container {
  background-color: $gray-200;
}

.interior-image {
  margin-top: 10rem;
  padding-bottom: 1rem;
  height: 35rem;

  img {
    width: 75%;
    height: 100%;
    object-fit: contain;
  }
}

.grade-description {
  font-size: 1.125rem;
  font-style: italic;
}

.alt-car-img {
  height: 45rem;
}

@media (max-width: 991.98px) {
  .btn-fullscreen {
    display: none;
  }

  .interior-image {
    height: unset !important;

    img {
      width: 100%;
    }
  }

  .alt-car-img {
    height: unset;
    width: 100%;
  }
}
