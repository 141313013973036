@font-face {
  font-family: "Renault";
  src: url('../fonts/renault-regular.woff2') format("woff2"), url('../fonts/renault-regular.woff') format("woff");
  font-weight: 400;
  font-display: swap;
  unicode-range: u+000-20cf
}

@font-face {
  font-family: "Renault";
  src: url('../fonts/renault-bold.woff2') format("woff2"), url('../fonts/renault-bold.woff') format("woff");
  font-weight: 700;
  font-display: swap;
  unicode-range: u+000-20cf
}

body {
  font-family: "Renault", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
