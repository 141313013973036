.modal-header {
  box-shadow: 0 0 3px rgba(0,0,0,.25);
  .btn-close {
    margin-right: 0;
    border: 1px solid;
    border-radius: 50%;
    font-size: 0.75rem;
  }
}
.modal-title {
  margin: 0;
  text-transform: uppercase;
}
.modal-content {
  border-radius: 0;
}