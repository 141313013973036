@import 'src/assets/scss/import';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';

.navbar-dark {
  background-color: $secondary;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  .navbar-toggler {
    border-color: transparent;
    font-size: inherit;
    color: $white;
    font-weight: 500;
    &:focus {
      box-shadow: none;
    }
  }
}
.navbar > .container {
  justify-content: center;
}

.navbar-collapse {
  font-weight: 500;
  color: $white;
  margin-top: 1.5rem;
  font-size: 1rem;
  @include media-breakpoint-up(lg) {
    margin-top: 0;
    font-size: 0.8rem;
  }
}
.navbar-brand {
  line-height: 2;
  position: absolute;
}
.navbar-nav a {
  text-align: center;
  text-decoration: none;
  color: $gray-400;

  &.active {
    color: inherit;
  }

  .badge {
    display: grid !important;
    place-content: center;
    width: 1.875rem;
    height: 1.875rem;

    small {
      font-size: 0.9rem;
      font-weight: $font-weight-bold;
    }
  }

  &.active .badge {
    background-color: $white !important;
    color: $secondary;
  }
}
