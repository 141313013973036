@import 'src/assets/scss/import';
@import '~react-multi-carousel/lib/styles.css';

.color-swatch {
  min-width: 55px;
  text-align: center;
  font-size: 11px;
  color: $gray-800;
  padding: 0 10px;
  line-height: 2rem;
  background-color: transparent !important;
  border: none;
  margin: 0;

  &:hover {
    background-color: unset !important;
  }
}

.swatch {
  width: 55px;
  position: relative;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding: 2px;
    border-radius: 100px;
  }
  svg {
    position: absolute;
    right: 0;
    bottom: 36px;
    border-radius: 50%;
    background-color: $primary;
    color: $white;
    visibility: visible;
  }

  &.active {
    img {
      border: 1px solid $gray-400;
    }
  }

  &-text {
    height: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;

    small {
      font-size: 0.75rem;
    }
  }
}
