@import '~bootstrap/scss/_buttons.scss';

.btn {
  min-width: 10rem;
  padding: 10px 30px;
  border-radius: 0;
  font-weight: 700;
  &-check {
    &:active + .btn-light,
    &:focus + .btn-light {
      box-shadow: none !important;
    }
  }
}

.btn-light {
  border-color: $secondary;
  background-color: $white;
  color: $secondary;
  &:hover,
  &:focus,
  &:active {
    background-color: $secondary;
    color: $white;
  }
}

.btn-primary {
  color: $black;
  &:hover,
  &:focus,
  &:active {
    color: $black;
    border-color: $primary-light;
    background-color: $primary-light;
  }
}

.btn-link {
  min-width: auto;
  color: $secondary;
  text-decoration: none;
  border-radius: 0;
  font-size: 15px;
  white-space: nowrap;
  padding: 0;
  &:hover,
  &:focus,
  &:active {
    color: $secondary;
    text-decoration: underline;
  }
  svg {
    width: 20px;
    height: 20px;
    color: $gray-500;
    margin-right: 0.5rem;
  }
}

.btn-icon-left {
  svg {
    margin-right: 0.5rem;
  }
}

.btn-icon-right {
  svg {
    margin-left: 0.5rem;
  }
}

.btn-round {
  display: grid;
  place-content: center;
  width: 2rem;
  height: 2rem;
  min-width: unset;
  padding: 0;
  background-color: $white;
  color: $primary;
  border: 2px solid $gray-400;

  &-large {
    width: 2.875rem;
    height: 2.875rem;
  }
}
