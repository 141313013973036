h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 700;
  line-height: 1.2;
}
h2, .h2 {
  font-size: 2.25rem;
  line-height: 1;
  @include media-breakpoint-up(md) {
    font-size: 3.125rem;
  }
}
h3, .h3 {
  font-size: 1.5rem;
  @include media-breakpoint-up(md) {
    font-size: 1.75rem;
  }
}
h4, .h4 {
  font-size: 1.375rem;
  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
  }
}
h5, .h5 {
  font-size: 1.25rem;
}
h6, .h6 {
  font-size: 1.125rem;
}
/*h6, .h6 {
  font-size: 0.875rem;
}*/

small {
  font-size: 0.875rem;
}

a svg {
  height: 0.875rem;
  width: 0.875rem;
}

$consent-fs: 0.8rem;

.consent {
  font-size: $consent-fs;

  * {
    font-size: $consent-fs;
  }

  p {
    margin-bottom: 0;
  }
}
