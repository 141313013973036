@import 'src/assets/scss/import';

.conclusion .selected-accessories-toggle {
  cursor: pointer;

  svg {
    color: $primary;
    transition: $transition-base;
  }

  &-open {
    svg {
      transform: rotate(-180deg);
    }
  }
}

.list-group-item.borderless {
  border: none;
}
