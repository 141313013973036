@import '../../../assets/scss/import';

.techdata-list {
  padding: 0;

  li {
    display: flex;
    justify-content: space-between;
    padding: 0.25rem 0.5rem;

    &:nth-child(even) {
      background-color: $light;
    }
  }
}
