@import 'src/assets/scss/import';

$bottom-bar-height-sm: 5.375rem;
$bottom-bar-height-expanded: 650px;
$bottom-bar-height-mobile: 100%;

.bottom-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 5.375rem;
  background-color: $light;
  box-shadow: $box-shadow-up;
  z-index: 1002;
  transition: 300ms height ease-in-out;

  &-content {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &-basic {
    display: flex;
    height: $bottom-bar-height-sm;
    background-color: $white;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;

    &-info {
      display: flex;
      align-items: center;

      span:first-of-type {
        font-size: $font-size-sm;
      }
    }
  }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.35);
    z-index: 1001;
  }

  &-expanded .expander-btn svg {
    transform: unset !important;
  }

  .expander-btn {
    position: absolute;
    top: -1rem;
    left: 50%;
    transform: translateX(-50%);

    svg {
      transform: rotateX(180deg);
      margin: 0;
    }
  }

  .cart {
    &-btn {
      color: $black;
      margin-right: 1rem;
    }

    &-content {
      height: calc(100vh - 10rem);
      padding: 0 1rem 1rem;
      overflow: auto;
      overflow-y: scroll;
    }

    &-info p {
      margin: 0;

      &:first-of-type {
        margin-bottom: 0.25rem;
      }
    }

    &-items {
      margin-top: 1.5rem;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid $gray-400;
      height: 3.625rem;
      width: 100%;
    }
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .bottom-bar-expanded {
    height: $bottom-bar-height-expanded;
    max-height: 70vh;
  }
}

@media (max-width: 575.98px) {
  .bottom-bar-expanded {
    height: $bottom-bar-height-mobile;

    .bottom-bar-basic {
      flex-wrap: wrap;
      height: 10rem;
      padding: 2rem 1rem 1rem;

      > * {
        flex-basis: 100%;
      }

      &-info {
        justify-content: flex-start;
      }
    }

    .expander-btn {
      display: none;
    }
    .btn-close {
      position: absolute;
      top: 0.5rem;
      right: 1rem;
    }
  }

  .bottom-bar:not(.bottom-bar-expanded) {
    .bottom-bar-basic-info span {
      max-width: 100px;
    }
  }
}
