@import "~bootstrap/scss/_accordion.scss";

.accordion-item {
  border-right: none;
  border-left: none;
  border-top: none;
  hr {
    opacity: 0.2;
  }
}
.accordion-body {
  padding-bottom: 0;
}
.accordion-header {
  font-weight: 400;
}
.accordion-button.collapsed:after {
  color: $primary;
}
