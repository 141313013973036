@import '../../../assets/scss/import';

.grade-card.card {
  border: 1px solid transparent;

  .card-title {
    height: 1.625rem;
  }

  a {
    text-decoration: none;
    color: inherit;

    hr {
      height: 3px;
      background-color: transparent;
      border-bottom: 1px solid $gray-200;
      opacity: 1;
    }

    &:hover hr {
      background-color: $primary;
      transition: $carousel-transition;
      border-bottom: 1px solid $primary;
    }
  }
}
