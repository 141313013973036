@import 'variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/utilities/api';
@import '~bootstrap/scss/transitions';

@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/spinners';

@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/placeholders';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/card';

html,
body {
  height: 100%;
  color: $secondary;
  line-height: 1.5rem;
}

::-moz-selection {
  background: $primary;
}
::selection {
  background: $primary;
}
