@import 'src/assets/scss/import';

@import '~swiper/swiper';

// Swiper

.swiper {
  width: 100%;

  &-nav-btn {
    width: 3rem;
    color: $primary;

    svg {
      margin-top: 2.5rem;
    }

    &.swiper-button-disabled {
      opacity: 0;
    }
  }

  &-next svg {
    transform: rotate(180deg);
  }

  &-slide.bordered {
    position: relative;

    &:after {
      position: absolute;
      bottom: 0;
      height: 55px;
      width: 1px;
      background-color: $gray-400;
      content: ' ';
    }
  }
}
