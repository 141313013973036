@import 'src/assets/scss/import';

$bottom-border: 1px solid rgba(0, 0, 0, 0.125);

$trigger-height: 3.25rem;

.accessories {
  &-filter {
    width: 100%;
    box-shadow: $box-shadow;
    border-radius: 4px;
    padding: 1.75rem;
    background-color: $white;

    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      height: 3rem;
      border-bottom: $bottom-border;
      cursor: pointer;

      svg {
        &:first-of-type {
          margin-right: 1.5rem;
        }

        &:nth-of-type(2) {
          margin-left: auto;
        }
      }

      &-active {
        font-weight: $font-weight-bold;

        svg:first-of-type {
          color: $primary;
        }
      }
    }

    &-mobile {
      .collapse {
        padding: 1.5rem 0;
      }
    }
  }

  &-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $font-size-sm;
    padding-bottom: 1.5rem;
    margin-top: 3rem;
  }
}

.search-input {
  position: relative;

  .btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 10;

    svg {
      color: $body-color !important;
    }
  }
}

.filter-trigger {
  position: relative;
  border-bottom: $bottom-border;

  .btn {
    display: flex;
    align-items: center;
    height: $trigger-height;
    width: 100%;

    svg {
      color: $body-color !important;
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  .badge {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0.65rem;
    left: 0.875rem;
    width: 0.875rem;
    height: 0.875rem;
    background-color: $white !important;
    border: 2px solid $primary;
    color: $primary;
    border-radius: 50%;
    font-size: 0.65rem;
  }
}

@media (min-width: 992px) {
  .accessories-filter {
    max-width: 33rem;
  }
}
