@import 'src/assets/scss/import';

.nav-tabs {
  border-bottom: none;
  font-size: $font-size-sm;
  font-weight: 500;
  padding-bottom: 0;
  .nav-link {
    margin-bottom: 0;
    color: $gray-800;
    line-height: 2rem;
    text-transform: uppercase;
  }
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  border-bottom: 3px solid $primary;
  border-radius: 0;
  font-weight: $font-weight-bold;
}
